<template>
  <div class="userinfo-dashboard flex">
    <div class="left">
      <div class="box black">
        <div class="label flex-center">
          {{ $t('yueusd') }}
          <img
            :src="showMoney ? eyeUrl2 : eyeUrl"
            alt=""
            @click="showMoney = !showMoney"
          />
        </div>
        <div class="count">{{ showMoney ? userMoney.toFixed(2) : '****' }}</div>
      </div>
      <div class="box" @click="toPath('userInfoCollect')">
        <div class="count">{{ goodsList.length }}</div>
        <div class="label">{{ $t('shang-pin-shou-cang') }}</div>
      </div>
      <div class="box" @click="toPath('userInfoFollowShop')">
        <div class="count">{{ shopList.length }}</div>
        <div class="label">{{ $t('guan-zhu-dian-pu') }}</div>
      </div>

      <div class="list-box">
        <div class="title">{{ $t('guan-zhu-dian-pu-0') }}</div>
        <div class="list-main">
          <div class="shop-list">
            <div class="list" v-for="(item, i) in shopList" :key="i">
              <div class="flex-center">
                <div class="img">
                  <img :src="item.Shop.Logo" alt="" />
                </div>
                <div class="flex-1">
                  <div class="name">{{ item.Shop.Name }}</div>
                  <div class="info">
                    {{ $t('yi-shou') }}
                    {{ item.Shop.TotalDeal }}
                  </div>
                  <div class="info">
                    {{ $t('ping-fen') }}
                    {{ item.Shop.GoodsScore }}
                  </div>
                </div>
              </div>
              <div class="btn">
                <el-button @click="toShop(item)">{{
                  $t('fang-wen-shang-dian')
                }}</el-button>
              </div>
            </div>
          </div>
          <div class="empty" v-if="shopList.length == 0">
            <img :src="emptyUrl" alt="" />
            <span>{{ $t('zan-wu-shu-ju') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <div class="box" @click="toPath('userInfoRecharge')">
        <div class="label">{{ $t('chong-zhi') }}</div>
        <div class="icon">
          <i class="el-icon-top"> </i>
        </div>
      </div>
      <div class="list-box">
        <div class="title">{{ $t('shang-pin-shou-cang-0') }}</div>
        <div class="list-main">
          <div class="shop-list clear">
            <div class="list small" v-for="(item, i) in goodsList" :key="i">
              <div class="flex-center">
                <div class="img">
                  <img :src="item.img" alt="" />
                </div>
                <div class="flex-1">
                  <div class="goods-title">{{ item.name }}</div>
                  <div class="price">${{ item.price }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="empty" v-if="goodsList.length == 0">
            <img :src="emptyUrl" alt="" />
            <span>{{ $t('zan-wu-shu-ju') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="box" @click="toPath('userInfoWithdraw')">
        <div class="label">{{ $t('ti-xian') }}</div>
        <div class="icon">
          <i class="el-icon-top"> </i>
        </div>
      </div>
      <div class="list-box">
        <div class="title">{{ $t('mo-ren-di-zhi') }}</div>
        <div class="address-list">
          <div class="list" v-for="(item, i) in addressList" :key="i">
            <div class="flex-center-between">
              <div class="info">{{ item.FullName }} {{ item.Phone }}</div>
              <div>
                <el-switch
                  @change="setDefault(item)"
                  v-model="item.isDefault"
                  active-color="#9d48f7"
                ></el-switch>
              </div>
            </div>
            <div class="address">
              {{ item.Country }} {{ item.Province }} {{ item.City }}
              {{ item.Address }}
            </div>
          </div>
        </div>
        <div class="list-main" v-if="addressList.length == 0">
          <div class="empty">
            <img :src="emptyUrl" alt="" />
            <span>{{ $t('zan-wu-shu-ju') }}</span>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="flex-center add" @click="addressDialog = true">
          <i class="el-icon-plus"></i>
          {{ $t('xin-zeng-di-zhi') }}
        </div>
      </div>
    </div>

    <addressDialog
      :showDialog="addressDialog"
      @init="initAddress"
      @close="addressDialog = false"
    ></addressDialog>
  </div>
</template>
<script>
import {
  shippingAddressList,
  setDefaultAddress,
  getWalletInfo
} from '@/api/user'
import { getShopFollowList } from '@/api/shop'
import addressDialog from './address.vue'
export default {
  components: {
    addressDialog
  },
  data() {
    return {
      eyeUrl: require('@/assets/imgs/icon-eye.png'),
      eyeUrl2: require('@/assets/imgs/icon-eye2.png'),
      emptyUrl: require('@/assets/imgs/emtpy.svg'),
      showMoney: true,
      addressDialog: false,
      userMoney: 0,
      form: {
        phonePre: '+86',
        default: false
      },
      shopList: [],
      goodsList: [],

      addressList: []
    }
  },
  computed: {},
  mounted() {
    this.initAddress()
    this.init()
    this.initFollow()
  },
  methods: {
    init() {
      getWalletInfo().then((res) => {
        let data = res.data.Items[0]
        this.userMoney = data.Balance
      })
    },
    initFollow() {
      getShopFollowList({
        current: 1,
        pageSize: 5
      }).then((res) => {
        this.shopList = res.data.Items
      })
    },
    initAddress() {
      shippingAddressList({
        Current: 1,
        PageSize: 10
      }).then((res) => {
        this.addressList = res.data.Items.map((v) => {
          return {
            ...v,
            isDefault: v.IsSelected == 1
          }
        })
      })
    },
    toPath(path) {
      this.$router.push({
        name: path
      })
    },
    setDefault(data) {
      setDefaultAddress({
        ID: data.ID
      }).then((res) => {
        if (res.code == 0) {
          this.initAddress()
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    toShop(data) {
      this.$router.push({
        name: 'shopInfo',
        query: {
          id: data.ID
        }
      })
    }
  }
}
</script>