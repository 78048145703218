<template>
  <el-dialog
    :title="$t('tian-jia-di-zhi-xiu-gai-di-zhi')"
    :visible.sync="addressDialog"
    width="600px"
    @close="closeDialog"
    class="auth-dialog"
  >
    <el-form :model="form" ref="form" :rules="formRule">
      <el-form-item label="" prop="FullName">
        <el-input
          v-model="form.FullName"
          :placeholder="$t('qing-shu-ru-shou-huo-ren-xing-ming')"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="" prop="email">
        <el-input v-model="form.email" :placeholder="'请输入邮箱'"></el-input>
      </el-form-item> -->
      <el-form-item label="" prop="Phone">
        <el-input
          class="phone-input"
          v-model="form.Phone"
          :placeholder="$t('qing-shu-ru-shou-ji-hao-3')"
        >
          <template slot="prefix">
            <div class="flex-center-start phone-pre">
              <i class="el-icon-mobile-phone"></i>
              <el-dropdown
                placement="bottom-start"
                @command="chooseCountry"
                class="phone-pre-dropdown"
              >
                <span class="text flex-center">
                  {{ form.phonePre }}
                  <i class="el-icon-arrow-down"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <div class="phone-dropdown-item">
                    <el-input
                      v-model="keyword"
                      :placeholder="$t('sou-suo-1')"
                      @input="searchCountry"
                      @blur="searchCountry"
                    ></el-input>
                    <div
                      v-for="group in showCountryList"
                      :key="group.id"
                      :label="group.title"
                    >
                      <el-dropdown-item
                        v-for="item in group.children"
                        :key="item.key"
                        :command="item.key"
                        >{{ item.title }}</el-dropdown-item
                      >
                    </div>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="" prop="Country">
        <el-input
          v-model="form.Country"
          :placeholder="$t('qing-shu-ru-guo-jia')"
        ></el-input>
        <!-- <el-select v-model="form.Country" :placeholder="'请选择国家'">
          <el-option-group
            v-for="group in countryList"
            :key="group.id"
            :label="group.title"
          >
            <el-option
              v-for="item in group.children"
              :key="item.key"
              :label="item.title"
              :value="item.title"
            >
            </el-option>
          </el-option-group>
        </el-select> -->
      </el-form-item>
      <el-form-item label="" prop="Province">
        <el-input
          v-model="form.Province"
          :placeholder="$t('qing-shu-ru-sheng-zhou-bang')"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="City">
        <el-input
          v-model="form.City"
          :placeholder="$t('qing-shu-ru-cheng-shi')"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="ZipCode">
        <el-input
          v-model="form.ZipCode"
          :placeholder="$t('qing-shu-ru-you-bian')"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="Address">
        <el-input
          v-model="form.Address"
          :placeholder="$t('qing-shu-ru-xiang-xi-di-zhi')"
          type="textarea"
          :rows="4"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="default">
        <div class="flex-center-between">
          <div>{{ $t('she-wei-mo-ren-di-zhi') }}</div>
          <el-switch v-model="form.default" active-color="#9d48f7"></el-switch>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button class="form-submit-btn" @click="submit">{{
          $t('que-ding')
        }}</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import { addShippingAddress } from '@/api/user'
export default {
  data() {
    return {
      addressDialog: false,
      form: {
        phonePre: '+86',
        Phone: '',
        FullName: '',
        Country: '',
        Province: '',
        City: '',
        Address: '',
        ZipCode: ''
      },
      showCountryList: [],
      keyword: '',
      formRule: {
        FullName: [
          {
            required: true,
            message: this.$t('qing-shu-ru-shou-huo-ren-xing-ming-0')
          }
        ],
        Phone: [{ required: true, message: this.$t('qing-shu-ru-dian-hua') }],
        Country: [{ required: true, message: this.$t('qing-xuan-ze-guo-jia') }],
        Province: [
          { required: true, message: this.$t('qing-shu-ru-sheng-zhou-bang-0') }
        ],
        City: [{ required: true, message: this.$t('qing-shu-ru-cheng-shi-0') }],
        Address: [
          { required: true, message: this.$t('qing-shu-ru-xiang-xi-di-zhi-0') }
        ],
        ZipCode: [
          { required: true, message: this.$t('qing-shu-ru-you-bian-0') }
        ]
      }
    }
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    showDialog() {
      this.addressDialog = this.showDialog
    }
  },
  computed: {
    countryList() {
      return this.$store.state.countryList
    }
  },
  mounted() {
    this.addressDialog = this.showDialog
    this.showCountryList = [].concat(this.countryList)
  },
  methods: {
    closeDialog() {
      this.$emit('close')
    },
    searchCountry() {
      if (!this.keyword) {
        this.showCountryList = [].concat(this.countryList)
      } else {
        let allList = JSON.parse(JSON.stringify(this.countryList))
        this.showCountryList = allList.map((v) => {
          v.children = v.children.filter((val) => {
            return (
              val.key.indexOf(this.keyword) > -1 ||
              val.title.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
            )
          })
          return v
        })
      }
    },
    chooseCountry(data) {
      this.form.phonePre = data
    },
    clear() {
      this.form.password = ''
    },
    submit() {
      this.$refs.form.validate((vali) => {
        if (vali) {
          addShippingAddress({
            Phone: this.form.phonePre + this.form.Phone,
            FullName: this.form.FullName,
            Country: this.form.Country,
            Province: this.form.Province,
            City: this.form.City,
            Address: this.form.Address,
            ZipCode: this.form.ZipCode
          }).then((res) => {
            if (res.code == 0) {
              this.$message.success(this.$t('tian-jia-cheng-gong'))
              this.$emit('init')
              this.closeDialog()
            } else {
              this.$message.warning(res.msg)
            }
          })
        }
      })
    }
  }
}
</script>